const ACTIONS = [
  {
    text: 'Delete',
    value: 'delete',
    tier: 1,
  },
  {
    text: 'Approve',
    value: 'approve',
    tier: 2,
  },
  {
    text: 'Send to Email',
    value: 'send_to_email',
    tier: 3,
  },
  {
    text: 'Mark as Paid',
    value: 'mark_as_paid',
    tier: 3,
  },
  {
    text: 'Send to {{accounting}}',
    value: 'send_to_accounting',
    tier: 3,
  },
]

export default ACTIONS
